import React, { useEffect, useRef } from 'react';
import { createChart, ColorType, CandlestickData, ChartOptions, DeepPartial } from 'lightweight-charts';
import { CANDLE_CHART_COLORS } from '../../../utils/cssUtils';

interface ICandlestickChart {
    data: CandlestickData[] | undefined;
    isMobile: boolean;
    height: number;
}

const CandlestickChart = ({ data, isMobile, height }: ICandlestickChart) => {

    const chartContainerRef = useRef<HTMLDivElement | null>(null);

    const chartOptions: DeepPartial<ChartOptions> = {
        layout: {
            background: { type: ColorType.Solid, color: CANDLE_CHART_COLORS.backgroundColor },
            textColor: CANDLE_CHART_COLORS.textColor,
        },
        width: 0,
        height,
        timeScale: {
            fixRightEdge: true, // to fix right edge of the time scale
            fixLeftEdge: true, // to fix left edge of the time scale
            allowBoldLabels: true, // to allow bold labels
        },
    }

    useEffect(() => {
        if (!chartContainerRef.current) return;

        const handleResize = () => {
            if (chartContainerRef.current) {
                chart.applyOptions({ width: chartContainerRef.current.clientWidth });
            }
        };

        const chart = createChart(chartContainerRef.current, chartOptions);

        const dateNow = Date.now()

        // inital view of the chart
        chart.timeScale().setVisibleLogicalRange({ from: dateNow / 1000 - 40, to: dateNow / 1000 });

        const newSeries = chart.addCandlestickSeries();

        newSeries.setData(data || []);

        window.addEventListener('resize', handleResize);

        // eslint-disable-next-line consistent-return
        return () => {
            window.removeEventListener('resize', handleResize);
            chart.remove();
        };
    }, [data]);

    return <div style={{ maxWidth: '900px', width: isMobile ? '320px' : '780px' }} ref={chartContainerRef} />
};

export default CandlestickChart;
