import { FetchSuccess, FetchResponse, JsonResult } from '../types';
import { EnumReportTypes, ILoanValue } from '../types/reports';
import {
  DefaultFetchError,
  FetchCreate,
  FetchDelete,
  FetchGet,
  FetchGetId,
  FetchUpdate,
  useFetchCreate,
  useFetchDelete,
  useFetchGet,
  useFetchGetId,
  useFetchUpdate,
} from './fetch';
import { IProfile } from './users';

export interface IActivity {
  createdAt: string;
  date: string;
  id: string | number;
  reportId: string | number;
  type: string;
  updatedAt: string;
  url?: string;
}

export interface IReport {
  id: string;
  createdAt: string;
  status: 'completed' | 'in-progress' | 'edited' | 'draft';
  name: string;
  closingText: string;
  welcome_message: string | null;
  settings_logo: string | null;
  approvedAt: null;
  editedAt: string | null;
  viewedAt: string | null;
  Activities?: IActivity[];
  User?: IProfile;
  video: string | null;
  rentVsOwnValue: string | null;
  selectOptions: string | null;
  ReportSettings?: IReportSettingRow[];
  reportType?: EnumReportTypes;
  yearsSaving: number;
}

interface IChangeReportType {
  reportType: EnumReportTypes;
}

interface IYearsSaving {
  yearsSaving: number | string;
}

export interface IReportSettingRow {
  id?: number;
  text: string;
  order: number;
  active: boolean;
  name: string;
  color: string | null;
}

export interface IReportSettings {
  tableView: IReportSettingRow[];
  reportView: IReportSettingRow[];
}

export interface IReportSettingsParams {
  reportView?: IReportSettingRow[];
  tableView?: IReportSettingRow[];
}

export interface IReportsGetParams {
  userId: string | number;
  page?: number;
  limit?: number;
  resent?: boolean;
}

interface IReportsTableData {
  reports: IReport[];
  total: number;
}

interface ISavingResponse {
  yearsSaving: number;
  monthsSaving: number;
  savingArray: string[];
  totalSaving: number;
}

interface IReportsResponse {
  data: {
    count: number;
    rows: IReport[];
  };
  success: boolean;
}

export interface IReportShareParams {
  email?: string;
  phoneNumber?: string;
  link: string;
}

export interface IReportLongTerm {
  amortisationGainArray: number[];
  taxBerettaArray: number[];
  appreciationGainArray: number[];
  totals: {
    amortisationGainTotal: number;
    taxBerettaTotal: number;
    appreciationGainTotal: number;
  };
}

export interface IReportLoansParams {
  id?: string | number;
}

export interface ISavingMonthly {
  id: string | number;
  montlySavingIncrease: string | number;
}

export interface IReportLongTermParams {
  years?: number;
}

export interface IReportBreakEvenPoint extends IReportLoansParams {
  interestRateWithPoint?: number | string | null;
  interestRateWithoutPoint?: number | string | null;
  totalLoanPoints?: number | string | null;
}

export interface IReportTaxAdvantage {
  taxBenefitPerMonth: string | null;
  taxBenefitPerYear: string | null;
  afterTaxPayment: string | null;
}

export interface IReportMortgageIntrerest {
  data?: {
    mortgageInterest?: number | string;
  };
}

export interface IReportTaxAdvantageParams extends IReportLoansParams {
  taxRate?: number | string;
  stateTaxRate?: number | string;
  realEstateTaxes?: number | string;
  otherDeductions?: number | string;
  fillingStatus?: number | string;
  mortgageInterest?: number | string;
}

export interface IReportBuyDownRow {
  annualSubsidyPayments?: string;
  interestRate?: string;
  monthlySubsidy?: string;
  numPaymentsPerYear?: number;
  payment?: number | string;
  paymentWithSubstitudy?: string;
  buyDownCost?: string;
}

export interface IReportInsuranceParams {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  dateOfBirth: string;
  propertyAddress: string;
  isInvestment: boolean;
  isRefinance: boolean;
  reportId: string | undefined;
}

export type IReportRentalAnalysisKey =
  | 'avg'
  | 'bedrooms0'
  | 'bedrooms1'
  | 'bedrooms2'
  | 'bedrooms3'
  | 'bedrooms4'
  | 'bedrooms5'
  | 'averageRent'
  | 'bedrooms0Average'
  | 'bedrooms1Average'
  | 'bedrooms2Average'
  | 'bedrooms3Average'
  | 'bedrooms4Average'
  | 'bedrooms5Average';

export interface IReportRentalAnalysisRow {
  month: string;
  bedrooms: number;
  averageRent: number;
  minRent: number;
  maxRent: number;
  totalListings: number;
}

export type IReportRentalAnalysis = {
  [key in IReportRentalAnalysisKey]: IReportRentalAnalysisRow[];
};

export interface IReportRentalAnalysisParams extends IReportLoansParams {
  bedrooms?: number[];
  historyRange?: number;
  avg?: boolean;
}

export interface IReportCreateParams {
  name: string;
  status: string;
  loanColor: JsonResult;
}

export interface IReportUpdateVideo {
  video: string;
}

export interface IReportUpdateRentVsOwn {
  rentVsOwnValue: string;
}

export interface IReportUpdateParams {
  name?: string;
  status?: string;
  closing_text?: string;
}

export interface IReportDuplicateParams {
  reportId: string;
  userId: string | number;
}

export type IReportBuyDownKey = 'oneYearBuyDown' | 'twoYearsBuyDown' | 'threeYearsBuyDown';

export type IReportBuyDown = {
  [key in IReportBuyDownKey]: IReportBuyDownRow[];
};

export interface IReportBreakEventPoint {
  costOfPoints: string;
  currentInterestRate: string;
  interestRateWithPoint: string;
  monthlySavings: string;
  months: number;
  years: number;
  interestRateWithoutPoints?: string;
  totalLoanPoints?: string;
}

export interface IReportByWeeklyPayments {
  biWeeklyPayment: string;
  paidOffEarlyMonths: number;
  paidOffEarlyYears: number;
  savingsAmount: string;
  savingsPerYear: string[];
  totalInterestSaved: string;
}

export interface IReportLoanCreateParams {
  [key: string]: string | number | undefined;
}

export interface IReportLoan {
  order: string;
  [key: string]: string;
}

export interface IReportProperty extends JsonResult {
  streetAddress: string;
  zpid: string;
  city: string;
  country: string;
  state: string;
  zipcode: string;
}

export interface IReportLocationSearch {
  results: IReportProperty | IReportProperty[];
}

export interface IReportPropertyDetails {
  purchasePrice: number | null;
  hoaDues: number | null;
  rentValue: number | null;
  propertyTaxes: string | null;
  priceStatus: 'List Price' | 'Estimated Value';
  supplyMentalTaxBill: string | null;
  suplimentalData: string;
  taxRate: number | null;
  taxHistory: {
    taxIncreaseRate: number | null;
    taxPaid: number | null;
    time: number | null;
    value: number | null;
    valueIncreaseRate: number | null;
  };
}

export interface IReportRecalculation {
  propertyTaxes: string | number | null;
  recalculateTax: boolean;
}

export interface IReportRecalculatSuplimentalTax {
  supplyMentalTaxBill: string | number | null;
}
export interface IReportRecalculationParams {
  zpid: string;
  loanId: string;
  recalculateTax: boolean;
}

export interface IReportRecalculationSuplimentalTaxParams {
  zpid: string;
  purchasePrice: string;
}

export interface IReportPropertyDetailsParams {
  zpid: string;
}

export interface IAddUip {
  addUip: boolean;
}

export interface IHidden {
  hidden: boolean;
}

export interface IIsSegment {
  isSegment: boolean;
}

export interface IInterestOnly {
  interestOnly: boolean;
}

export interface IReportCount {
  createdReportsCount: number;
  openReportsCount: number;
  referallsCount: number;
  sendReportsCount: number;
  referralsTillNowDifCount: number;
  reportsTillNowDiffCount: {
    created: number;
    open: number;
    send: number;
  };
}

export interface IReportLocationSearchParams {
  location: string;
}

export interface ICurrentMarketRates {
  open: number;
  high: number;
  low: number;
  close: number;
  time: string;
}

export interface ICurrentMarketRatesParams {
  years?: number;
}

export interface IReportTrackActivitiesParams {
  id: string | number;
  type: string;
}

export function useReports<D = IReportsResponse, DD = D>(
  decorateData?: (data: D) => DD
): FetchGet<DD, IReportsGetParams> {
  return useFetchGet<D, DefaultFetchError, unknown, DD>('reports', { decorateData, autoStart: false });
}

export const useTableReportsRow = (): FetchGet<IReportsTableData, IReportsGetParams> =>
  useReports(
    (data: IReportsResponse): IReportsTableData => ({
      reports: data.data.rows.map((item) => ({
        ...item,
        key: item.id,
      })),
      total: data.data.count,
    })
  );

export const useReportId = (): FetchGetId<FetchResponse<IReport>> =>
  useFetchGetId('reports', '', { autoStart: false, startStateLoading: false, multiple: 'report' });

export const useReportCreate = (): FetchCreate<FetchResponse<IReport>, DefaultFetchError, IReportCreateParams> =>
  useFetchCreate('reports', { autoStart: false });

export const useReportUpdate = (): FetchUpdate<FetchResponse<IReport>, DefaultFetchError, IReportCreateParams> =>
  useFetchUpdate('reports', '');

export const useReportVideoUpdate = (): FetchUpdate<FetchSuccess, DefaultFetchError, IReportUpdateVideo> =>
  useFetchUpdate('reports/video', '');

export const useReporRentVsOwnUpdate = (): FetchUpdate<FetchSuccess, DefaultFetchError, IReportUpdateRentVsOwn> =>
  useFetchUpdate('reports/rentVsOwn', '');

export const useReportDuplicate = (): FetchCreate<FetchResponse<IReport>, DefaultFetchError, IReportDuplicateParams> =>
  useFetchCreate('reports/duplicate', { autoStart: false });
export const useReportShare = (): FetchCreate<FetchSuccess, DefaultFetchError, IReportShareParams> =>
  useFetchCreate('reports/share', { autoStart: false });
export const useReportDelete = (): FetchDelete<FetchSuccess> => useFetchDelete('reports', '');

export const useReportLoans = (): FetchGet<FetchResponse<IReportLoan[]>, IReportLoansParams> =>
  useFetchGet('loans', { autoStart: false, startStateLoading: false, multiple: 'report-loans' });
export const useReportLoanCreate = (): FetchCreate<FetchSuccess, DefaultFetchError, ILoanValue[]> =>
  useFetchCreate('loans', { autoStart: false });
export const useReportLoanUpdate = (): FetchUpdate<FetchSuccess, DefaultFetchError, ILoanValue[]> =>
  useFetchUpdate('loans');
export const useReportLoanToggle = (): FetchUpdate<FetchSuccess, DefaultFetchError, IIsSegment> =>
  useFetchUpdate('loans/isSegment', '');
export const useReportInterestOnlyToggle = (): FetchUpdate<FetchSuccess, DefaultFetchError, IInterestOnly> =>
  useFetchUpdate('loans/interestOnly', '');
export const useReportLoanAddUip = (): FetchUpdate<FetchSuccess, DefaultFetchError, IAddUip> =>
  useFetchUpdate('loans/addUip', '');
export const useReportLoanHidden = (): FetchUpdate<FetchSuccess, DefaultFetchError, IHidden> =>
  useFetchUpdate('loans/hidden', '');
export const useReportLoanDelete = (): FetchDelete<FetchSuccess> => useFetchDelete('loans');

export const useReportSettingsById = (): FetchGetId<FetchResponse<IReportSettings>> =>
  useFetchGetId('reportSettings', '', { autoStart: false, startStateLoading: false, multiple: 'report-settings' });
export const useReportSettingsUpdate = (): FetchUpdate<
  FetchResponse<IReportSettings>,
  DefaultFetchError,
  IReportSettingsParams
> => useFetchUpdate('reportSettings', '');

export const useReportLongTerm = (): FetchGetId<
  FetchResponse<IReportLongTerm>,
  DefaultFetchError,
  IReportLongTermParams
> => useFetchGetId('loans/long-term', '', { autoStart: false, startStateLoading: false, multiple: 'long-term' });
export const useReportBuyDown = (): FetchGet<FetchResponse<IReportBuyDown>, IReportLoansParams> =>
  useFetchGet('loans/buy-down', { autoStart: false, startStateLoading: false, multiple: 'buy-down' });
export const useReportBreakEvenPoint = (): FetchGet<FetchResponse<IReportBreakEventPoint>, IReportBreakEvenPoint> =>
  useFetchGet('loans/break-even-point', { autoStart: false, startStateLoading: false, multiple: 'break-even-point' });
export const useReportTaxAdvantage = (): FetchGet<FetchResponse<IReportTaxAdvantage>, IReportTaxAdvantageParams> =>
  useFetchGet('loans/tax-advantage', { autoStart: false, startStateLoading: false, multiple: 'tax-advantage' });
export const useReportByWeeklyPayments = (): FetchGet<FetchResponse<IReportByWeeklyPayments>, IReportLoansParams> =>
  useFetchGet('loans/by-weekly-payments', {
    autoStart: false,
    startStateLoading: false,
    multiple: 'by-weekly-payments',
  });
export const useReportSavingMonthly = (): FetchGet<FetchResponse<ISavingResponse>, ISavingMonthly> =>
  useFetchCreate('loans/calculateSavings', {
    autoStart: true,
    startStateLoading: false,
    multiple: 'activicalculateSavingsties',
  });

export const useMortgageInterest = (): FetchGetId<
  FetchResponse<IReportMortgageIntrerest>,
  DefaultFetchError,
  IReportLoansParams
> =>
  useFetchGetId('loans/mortgage-interest', '', {
    autoStart: false,
    startStateLoading: false,
    multiple: 'mortgage-interest',
  });

export const useReportRentalAnalysis = (): FetchGet<
  FetchResponse<IReportRentalAnalysis>,
  IReportRentalAnalysisParams
> =>
  useFetchGet('rentcast/rental-analysis', {
    autoStart: false,
    startStateLoading: false,
    multiple: 'rental-analysis',
  });

export const useReportRentalAnalysisInitial = (): FetchGet<FetchResponse<string | null>, IReportLoansParams> =>
  useFetchGet('rentcast', {
    autoStart: false,
    startStateLoading: false,
    multiple: 'rental-analysis',
  });

export const useReportTrackActivities = (): FetchCreate<
  FetchResponse<IReportLocationSearch>,
  DefaultFetchError,
  IReportTrackActivitiesParams
> =>
  useFetchCreate('reports/activites', {
    autoStart: false,
    startStateLoading: false,
    multiple: 'activities',
  });

export const useReportCount = (): FetchGet<FetchResponse<IReportCount>> =>
  useFetchGet('reports/count', {
    autoStart: false,
    startStateLoading: false,
    multiple: 'count',
  });

export const useReportLocationSearch = (): FetchGet<
  FetchResponse<IReportLocationSearch>,
  IReportLocationSearchParams
> =>
  useFetchGet('zillow/search-for-properties', {
    autoStart: false,
    startStateLoading: false,
    multiple: 'search-for-properties',
  });

export const useReportPropertyDetails = (): FetchGet<
  FetchResponse<IReportPropertyDetails>,
  IReportPropertyDetailsParams
> =>
  useFetchGet('zillow/property-details', {
    autoStart: false,
    startStateLoading: false,
    multiple: 'property-details',
  });

export const useReportRecalculateTax = (): FetchGet<FetchResponse<IReportRecalculation>, IReportRecalculationParams> =>
  useFetchGet('zillow/recalculateTax', {
    autoStart: false,
    startStateLoading: false,
    multiple: 'recalculateTax',
  });

export const useCurrentMarketRates = (): FetchGet<FetchResponse<ICurrentMarketRates[]>, ICurrentMarketRatesParams> =>
  useFetchGet('reports/current-market-rates', {
    autoStart: false,
    startStateLoading: false,
  });

export const useReportRequestInsurance = (): FetchCreate<
  FetchResponse<IReportLocationSearch>,
  DefaultFetchError,
  IReportInsuranceParams
> =>
  useFetchCreate('loans/request-insurance', {
    autoStart: false,
    startStateLoading: false,
  });

export const useReportFileUpload = (): FetchCreate<FetchResponse<string>, DefaultFetchError, FormData> =>
  useFetchCreate('reports/upload');

export const useReportUpdateReportType = ():
  FetchUpdate<FetchSuccess, DefaultFetchError, IChangeReportType> =>
  useFetchUpdate('reports/report-type', '');

export const useCalculateSavings = (): FetchUpdate<FetchResponse<number[]>,
  DefaultFetchError, IYearsSaving> =>
  useFetchUpdate('reports/years-saving', '');