import { Col, Form, Row, Space } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import { useReportByWeeklyPayments } from '../../../../../hooks/reports';
import { commafy } from '../../../../../utils/text';
import { IBarChartData } from '../../../../Common/Charts/types';
import TextWithLabel from '../../../../Common/TextWithLabel';
import ReportSection from '../Section';
import { useContextReports } from '../../../../../context/reports';
import MovingTo from '../../../../Common/MovingTo';
import BarChart from '../../../../Common/Charts/BarChart';

interface IInterestSaving {
  provided?: DraggableProvided;
}

const InterestSaving = ({ provided }: IInterestSaving) => {
  const name = 'interestSavings';
  const reportByWeeklyPayments = useReportByWeeklyPayments();

  const form = Form.useFormInstance();
  const { loans } = useContextReports();
  const movingTo = useWatch(['movingTo', name], form);
  const isSectionActive = useWatch(['reportView', name, 'active'], form);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [data, setData] = useState<IBarChartData>();

  useEffect(() => {
    if (
      // !movingTo ||
      !isSectionActive ||
      !loans
    )
      return;
    const loan = loans?.find((item) => item.id === movingTo);

    const delay = 1350;
    const timeoutId = setTimeout(() => {
      reportByWeeklyPayments.fetch({ id: loan ? loan.id : loans?.[0].id });
    }, delay);

    // eslint-disable-next-line consistent-return
    return () => clearTimeout(timeoutId);
  }, [movingTo, isSectionActive, loans]);

  useEffect(() => {
    if (!reportByWeeklyPayments.data?.data) return;

    const labels = Array.from(
      { length: reportByWeeklyPayments.data.data.savingsPerYear.length },
      (_, i) => moment().year() + i
    );

    const newData: IBarChartData = {
      labels,
      datasets: [
        {
          label: 'Amount of saving',
          data: reportByWeeklyPayments.data.data.savingsPerYear.map((value) => parseFloat(value.replace('-', ''))),
          backgroundColor: '#22A4C9',
          borderColor: '#22A4C9',
          maxBarThickness: 15,
          inflateAmount: 1,
          borderRadius: {
            topLeft: 4,
            topRight: 4,
          },
        },
      ],
    };


    setData(newData);
  }, [reportByWeeklyPayments.data]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <ReportSection
      id={name}
      name={name}
      title="Interest Savings for Biweekly Payments"
      description="This section shows how much money you can save on loan interest by paying your 
      mortgage biweekly instead of monthly. By splitting your mortgage payment in half 
      and paying every two weeks, you make 26 half payments per year, which is equal 
      to 13 full payments per year. This may seem like a minor adjustment, but it can 
      have a big impact on the total interest you pay over the life of your loan."
      provided={provided}
    >
      <Space style={{ padding: '10px' }} direction="vertical" size={20}>
        <Row>
          <Col span={16}>
            <Space size={72}>
              <TextWithLabel
                label="Interest Savings Over 30 years"
                value={`$${commafy(Number(reportByWeeklyPayments.data?.data.totalInterestSaved) || 0)}`}
              />
              {windowWidth >= 867 && (
                <TextWithLabel
                  label="Reduce Your Loan Term by"
                  value={`${reportByWeeklyPayments.data?.data.paidOffEarlyYears || 0}
              years, ${reportByWeeklyPayments.data?.data.paidOffEarlyMonths || 0} months`}
                />
              )}
            </Space>
          </Col>
          {windowWidth >= 867 && (
            <Col span={8}>
              <MovingTo name={name} />
            </Col>
          )}
          {windowWidth < 867 ? (
            <>
              <Col xl={12} span={24}>
                <TextWithLabel
                  label="Reduce Your Loan Term by"
                  value={`${reportByWeeklyPayments.data?.data.paidOffEarlyYears || 0} 
                years, ${reportByWeeklyPayments.data?.data.paidOffEarlyMonths || 0} months`} />
              </Col>
              <Col xl={12} span={24}>
                <MovingTo name={name} />
              </Col>
            </>
          ) : null}
        </Row>
        <BarChart data={data} mode="nearest" hideLegend loading={reportByWeeklyPayments.loading} />
      </Space>
    </ReportSection>
  );
};

InterestSaving.defaultProps = {
  provided: undefined,
};

export default InterestSaving;
