import { Col, Form as AntdForm, Input, Row } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import React, { Dispatch, SetStateAction } from 'react';
import { useContextUsers } from '../../../../../context/users';
import { login } from '../../../../../utils/inputRules';
import ImageUpload from '../../../../Common/ImageUpload';
import CustomSection from '../CustomSection';

interface IPersonalInfo {
  editModeState: [string | null, Dispatch<SetStateAction<string | null>>];
  handleFileUpload: (name: string, data: string) => void;
}

const formatPhoneNumber = (value: string) => {
  if (!value) return value;

  const phoneNumber = value.replace(/[^\d]/g, '');
  const phoneNumberLength = phoneNumber.length;

  if (phoneNumberLength < 4) return phoneNumber;

  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }

  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
};

const PersonalInfo = ({ editModeState, handleFileUpload }: IPersonalInfo) => {
  const sectionName = 'personalInfo';
  const form = useFormInstance();
  const [editMode, setEditMode] = editModeState;

  const { profile, profileUpdateLoading } = useContextUsers();

  const disabled = editMode !== sectionName;

  const handleEditModeChange = (isActive: boolean) => {
    setEditMode(isActive ? sectionName : null);
  };

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);

    form.setFieldsValue({ phoneNumber: formattedPhoneNumber });
  };

  return (
    <CustomSection
      form={form}
      label="Personal info"
      description="Update your photo and personal details."
      editable
      isEditModeActive={editMode === sectionName}
      handleEditModeChange={handleEditModeChange}
      controlsDisabled={!!editMode && editMode !== sectionName}
      controlsLoading={profileUpdateLoading}
    >
      <Row gutter={20}>
        <Col xl={12} span={24}>
          <AntdForm.Item name="firstName" label="First Name">
            <Input size="large" placeholder="Enter First Name" disabled={disabled} />
          </AntdForm.Item>
        </Col>
        <Col xl={12} span={24}>
          <AntdForm.Item name="lastName" label="Last Name">
            <Input size="large" placeholder="Enter Last Name" disabled={disabled} />
          </AntdForm.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col xl={12} span={24}>
          <AntdForm.Item name="phoneNumber" label="Phone Number">
            <Input
              size="large"
              placeholder="Enter Phone Number"
              disabled={disabled}
              onChange={handlePhoneNumberChange}
              value={form.getFieldValue('phoneNumber')}
            />
          </AntdForm.Item>
        </Col>
        <Col xl={12} span={24}>
          <AntdForm.Item name="email" label="Email" rules={login}>
            <Input size="large" placeholder="Enter Email" disabled={disabled} />
          </AntdForm.Item>
        </Col>
      </Row>
      <Row style={{padding:'10px'}}  gutter={20}>
        <Col xl={12} span={24}>
          <ImageUpload
            fileName="avatar.png"
            url={profile?.avatar}
            label="Profile Photo"
            cropShape="round"
            placeholder="/avatar-placeholder.png"
            handleFileUpload={(data) => handleFileUpload('avatar', data)}
          />
        </Col>
      </Row >
      <Row gutter={20}>
        <Col span={24}>
          <AntdForm.Item name="companyName" label="Company Name">
            <Input size="large" placeholder="Enter Company Name" disabled={disabled} />
          </AntdForm.Item>
        </Col>
        <Col span={24}>
          <AntdForm.Item name="websiteLink" label="Website">
            <Input size="large" placeholder="Enter Website" disabled={disabled} />
          </AntdForm.Item>
        </Col>
        <Col span={24}>
          <AntdForm.Item name="nmlsId" label="NMLS #">
            <Input size="large" placeholder="Enter NMLS #" disabled={disabled} />
          </AntdForm.Item>
        </Col>
        <Col span={24}>
          <AntdForm.Item name="address" label="Address">
            <Input size="large" placeholder="Enter Address" disabled={disabled} />
          </AntdForm.Item>
        </Col>
        </Row>
      <Row gutter={20}>
        <Col xl={12} span={24}>
          <ImageUpload
            fileName="logo.png"
            url={profile?.logo}
            label="Company Logo"
            cropShape="rect"
            placeholder="/logo.png"
            handleFileUpload={(data) => handleFileUpload('logo', data)}
          />
        </Col>
      </Row>
    </CustomSection>
  );
};

export default PersonalInfo;
