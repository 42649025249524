import React, { useEffect } from 'react';
import Stripe from 'stripe';
import { useCustomerPaymentMethods } from '../../../../../hooks/stripe';
import ContentCard from '../../../../Common/ContentCard';
import PaymentsList from '../../../../Common/PaymentsList';

interface ISelectPlan {
  selectedPaymentMethod: string | null;
  handleNext: (id: string | null) => void;
  handleBack: () => void;
  selectedPlan: string | null;
  promoCode: string | undefined;
  discount: any;
  plan: Stripe.Plan | undefined;
}

const Payment = ({ selectedPaymentMethod, handleNext, handleBack, selectedPlan, promoCode, discount, plan }
  : ISelectPlan): JSX.Element => {
  const customerPaymentMethods = useCustomerPaymentMethods();

  const loadPaymentMethods = () => {
    customerPaymentMethods.fetch();
  };

  useEffect(() => {
    loadPaymentMethods();
  }, []);

  const paymentMethods = customerPaymentMethods.data?.data.data;

  return (
    <ContentCard loading={customerPaymentMethods.loading}>
      <PaymentsList
        plan={plan}
        paymentMethods={paymentMethods}
        selectedId={selectedPaymentMethod}
        handleNext={handleNext}
        handleBack={handleBack}
        selectedPlan={selectedPlan}
        reloadPaymentMethods={loadPaymentMethods}
        loading={customerPaymentMethods.loading}
        promoCode={promoCode}
        discount={discount}
      />
    </ContentCard>
  );
};

export default Payment;
