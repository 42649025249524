import { Space, Alert, Input, Checkbox, Row, Col, Button, Typography, Form as AntdForm } from "antd";
import { useForm } from "antd/es/form/Form";
import qs from "qs";
import { useEffect } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useAuth, SignUp as ISignUp } from "../../../../../context/auth";
import { login, password } from "../../../../../utils/inputRules";
import { setDataInStorage, getDataInStorage } from "../../../../../utils/storage";
import styles from '../../index.module.less';

const SignUpForm = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { authorized, signUp, loading, error, clearResponseError } = useAuth();
    const [form] = useForm();
  
    useEffect(() => handleFocus(), []);
  
    useEffect(() => {
      if (authorized) {
        navigate('/setup', { replace: true });
      }
    }, [authorized]);
  
    useEffect(() => {
      clearResponseError();
    }, []);
  
    const handleSignUp = (values: ISignUp) => {
      const { ref } = qs.parse(location.search.replace('?', '')) as { [key: string]: string | undefined };
  
      setDataInStorage('signUpData', values);
  
      signUp({ ...values, ref });
    };
  
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.keyCode === 32) {
        e.preventDefault();
      }
    };
  
    useEffect(() => {
      const storedData = getDataInStorage('signUpData');
      const initialFormData = storedData || { email: '', nmlsId: '', password: '' };
  
      form.setFieldsValue(initialFormData);
    }, [form]);
  
    const handleFocus = () => {
      // const name = e?.target?.name ?? 'email'
      // if(!e?.target?.value) {
      //   form.setFields([
      //     {
      //       name,
      //       // @ts-ignore
      //       errors: [RULES[name][1]?.message],
      //     },
      //   ]);
      // }
    };
  
    const handleAlreadySignUp = () => {
      setDataInStorage('signUpData', {});
      navigate('/sign-in');
    };
  
    return (
      <Space direction="vertical" size={24}>
        <AntdForm form={form} layout="vertical" onFinish={handleSignUp} className={styles.form} autoComplete="off">
          {error ? (
            <AntdForm.Item>
              <Alert onClose={clearResponseError} message={error} type="error" closable showIcon />
            </AntdForm.Item>
          ) : null}
          <AntdForm.Item name="email" label="Email" rules={login} className="label-gray">
            <Input
              size="large"
              name="email"
              placeholder="Enter Email"
              onFocus={handleFocus}
              autoFocus
              onKeyDown={handleKeyDown}
            />
          </AntdForm.Item>
          <AntdForm.Item
            name="password"
            label="Password"
            rules={password}
            extra="Min 8 characters"
            hasFeedback
            className="label-gray"
          >
            <Input.Password
              size="large"
              name="password"
              onFocus={handleFocus}
              placeholder="Enter Password"
              onKeyDown={handleKeyDown}
            />
          </AntdForm.Item>
          <AntdForm.Item name="terms" valuePropName="checked">
            <Checkbox className="color-gray checkbox-large">
              By clicking, I accept{' '}
              <Link to="https://mortgagemaker.ai/terms-of-service/" target="_blank">
                Terms of Service
              </Link>{' '}
              and{' '}
              <Link to="https://mortgagemaker.ai/privacy-policy/" target="_blank">
                Privacy Policy
              </Link>
            </Checkbox>
          </AntdForm.Item>
          <AntdForm.Item shouldUpdate noStyle>
            {({ getFieldsValue, getFieldsError }) => {
              const { terms, email, password: passwordValue } = getFieldsValue();
              const formIsComplete =
                terms && email && passwordValue && getFieldsError().every((field) => !field.errors.length);
  
              return (
                <Row gutter={24}>
                  <Col span={24}>
                    <Button
                      size="large"
                      type="primary"
                      htmlType="submit"
                      block
                      loading={loading}
                      disabled={!formIsComplete}
                    >
                      Create Account
                    </Button>
                  </Col>
                </Row>
              );
            }}
          </AntdForm.Item>
        </AntdForm>
        <div className={styles.textBox}>
          <Typography.Text className={styles.text}>
            Already have an account?{' '}
            <a onClick={handleAlreadySignUp} style={{ color: '#1E0C68', cursor: 'pointer' }}>
              Sign In
            </a>
          </Typography.Text>
        </div>
      </Space>
    );
  };

  export default SignUpForm;