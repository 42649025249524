import { Col, Row, Typography, Button, FormInstance, Space } from 'antd';
import React from 'react';
import ContentCard from '../../../../Common/ContentCard';

import styles from './index.module.less';

interface ICustomSection {
    form: FormInstance;
    label: string;
    description: string;
    children: React.ReactNode;
    controlsDisabled?: boolean;
    controlsLoading?: boolean;
    cardLoading?: boolean;
    editable?: boolean;
    cancelButtonLabel?: string;
    saveButtonLabel?: string;
    editButtonLabel?: string;
    isEditModeActive?: boolean;
    handleEditModeChange?: (value: boolean) => void;
}

const CustomSection = ({
    form,
    label,
    editable,
    description,
    children,
    controlsDisabled,
    controlsLoading,
    cardLoading,
    cancelButtonLabel,
    saveButtonLabel,
    editButtonLabel,
    isEditModeActive,
    handleEditModeChange,
}: ICustomSection) => {
    const handleCancel = () => {
        handleEditModeChange?.(false);
        form.resetFields();
    };

    const handleSave = () => {
        form.submit();
    };

    const handleEdit = () => {
        handleEditModeChange?.(true);
    };

    return (
        <section>
            <Row gutter={[20, 20]}>
                <Col xl={8} span={24}>
                    <div className={styles.textBox}>
                        <Typography.Title level={4} className={styles.label}>
                            {label}
                        </Typography.Title>
                        <Typography.Text className={styles.description}>{description}</Typography.Text>
                    </div>
                </Col>
                <Col xl={16} span={24}>
                    <ContentCard
                        bordered
                        padding={20}
                    >
                        <Row justify='end'>
                            {editable &&
                                (isEditModeActive && !controlsDisabled ? (
                                    <Space size={4}>
                                        <Button
                                            type="default"
                                            size="large"
                                            onClick={handleCancel}
                                            disabled={controlsLoading}
                                            loading={controlsLoading}
                                        >
                                            {cancelButtonLabel}
                                        </Button>
                                        <Button
                                            type="primary"
                                            size="large"
                                            onClick={handleSave}
                                            disabled={controlsLoading}
                                            loading={controlsLoading}
                                        >
                                            {saveButtonLabel}
                                        </Button>
                                    </Space>
                                ) : (
                                    <Button
                                        type="primary"
                                        size="large"
                                        onClick={handleEdit}
                                        disabled={controlsDisabled || cardLoading}
                                    >
                                        {editButtonLabel}
                                    </Button>
                                ))}
                        </Row>
                        <ContentCard loading={!!cardLoading}>{children}</ContentCard>
                    </ContentCard>
                </Col>
            </Row>
        </section>
    );
};

CustomSection.defaultProps = {
    cardLoading: false,
    controlsLoading: false,
    controlsDisabled: false,
    editable: false,
    cancelButtonLabel: 'Cancel',
    saveButtonLabel: 'Save',
    editButtonLabel: 'Edit',
    isEditModeActive: false,
    handleEditModeChange: undefined,
};

export default CustomSection;
