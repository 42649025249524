import { Tabs, TabsProps } from 'antd';
import Wrapper from '../index';
import SignUpForm from '../Setup/SignUpForm';

const SignUp = (): JSX.Element => {
  const items: TabsProps['items'] = [
    {
      key: '0',
      label: '',
      children: (
        <Wrapper title="Sign Up" text="Get Mortgage Maker to manage your work">
          <SignUpForm />
        </Wrapper>
      ),
    },
    {
      key: '1',
      label: '',
    },
    // {
    //   key: '2',
    //   label: '',
    // },
    // {
    //   key: '3',
    //   label: '',
    // },
  ];

  return (
    <Tabs
      tabPosition="bottom"
      centered
      tabBarGutter={10}
      tabBarStyle={{ borderRadius: 4, height: 5 }}
      className="steps"
      items={items}
    />
  );
};

export default SignUp;
