import Icon from '@ant-design/icons';
import { Form, Select, Typography } from 'antd';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import clsx from 'clsx';
import React, { memo, useEffect, useState } from 'react';
import { useContextReports } from '../../../context/reports';
import { Checked } from '../Icon';
import styles from './index.module.less';

interface IMovingTo {
  name: string;
  movingTo?: string | number;
  label?: string;
}

const MovingTo = ({ name, movingTo, label }: IMovingTo) => {
  const form = useFormInstance();
  const { loans, handleReportUpdate } = useContextReports();
  const [value, setValue] = useState<string | undefined>(undefined);

  const handleChange = (loanId: string) => {
    setValue(loanId);
    form.setFieldValue(['movingTo', name], loanId);
    handleReportUpdate?.();
  };

  useEffect(() => {
    if (!loans) return;
    const options = form.getFieldValue(['report', 'selectOptions']);
    const selectedOption = options && JSON.parse(options)[name];

    handleChange(selectedOption || loans[0].id);
  }, [movingTo]);

  useEffect(() => {
    if (!loans) return;
    const selectedOption = form.getFieldValue(['movingTo', name]);
    const loan = loans.find((item) => item.id === selectedOption);

    if(loan?.hidden){
      const filteredLoans = loans.filter((item) => Boolean(item.hidden) === false);

      handleChange(filteredLoans[0]?.id);
    }
    
  }, [loans]);

  return (
    <div className={styles.wrapper}>
      <Form.Item label={label} name={['movingTo', name]} className="movingTo label-gray m-0 w-100">
        <Select
          size="large"
          popupClassName="select-popup"
          placement="bottomRight"
          optionLabelProp="name"
          onChange={handleChange}
          value={value}
          options={loans
            ?.map((loan, index) => ({
            value: loan.id,
            hidden: loan.hidden,
            label: (
              <div className={styles.optionRow}>
                <Typography.Text className={styles.optionIndex}>{index + 1}.</Typography.Text>
                <div className={styles.optionContent}>
                  <Typography.Title level={4} className={styles.optionName}>
                    {loan.name ? loan.name : `Option ${index + 1}`}
                  </Typography.Title>
                  <Typography className={styles.optionLocation}>{loan.address}</Typography>
                </div>
                <Icon component={Checked} className={clsx(styles.optionChecked, 'optionChecked')} />
              </div>
            ),
            name: loan.name || loan.location || `Option ${index + 1}`,
          }))?.filter((loan) => Boolean(loan.hidden) !== true)}
        />
      </Form.Item>
    </div>
  );
};

MovingTo.defaultProps = {
  movingTo: undefined,
  label: 'Loan Details',
};

export default memo(MovingTo);
