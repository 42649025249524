import { Space, Form as AntdForm } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useEffect, useState } from 'react';
import { useAuth } from '../../../../context/auth';
import { useContextNotifications } from '../../../../context/notifications';
import { useContextUsers } from '../../../../context/users';
import { IProfileUpdateParams } from '../../../../hooks/users';
import ComplianceLogos from './ComplianceLogos';
import Password from './Password';
import PersonalInfo from './PersonalInfo';

const Details = () => {
  const [form] = useForm();
  const { openNotification } = useContextNotifications();
  const editModeState = useState<string | null>(null);
  const [, setEditMode] = editModeState;
  const { user: { id } } = useAuth();
  const { profile, updateProfile } = useContextUsers();

  const handleProfileUpdate = (params?: IProfileUpdateParams) => {
    if (!updateProfile || !id) return;

    form.validateFields().then((values) => {
      const newValues = { ...values };

      Object.entries(newValues).forEach(([key, value]) => {
        if (!value) delete newValues[key];
      });

      delete newValues.newPasswordConfirm;

      updateProfile({ ...newValues, ...params }, id).then(() => {
        if (params && Object.hasOwn(params, 'avatar')) {
          setEditMode('personalInfo');
        } else if (params && Object.hasOwn(params, 'logo')) {
          setEditMode('companyInfo');
        } else {
          setEditMode(null);
        }
        openNotification?.({ message: 'Profile was updated successfully' });
      });
    });
  };

  const handleFileUpload = (name: string, data: string) => {
    handleProfileUpdate({ [name]: data });
  };

  useEffect(() => {
    form.resetFields();
  }, [profile]);

  return (
    <section>
      <AntdForm
        form={form}
        layout="vertical"
        onFinish={() => handleProfileUpdate()}
        autoComplete="off"
        initialValues={{ ...profile }}
      >
        <Space direction="vertical" size={20}>
          <PersonalInfo editModeState={editModeState} handleFileUpload={handleFileUpload} />
          <Password editModeState={editModeState} />
          <ComplianceLogos />
        </Space>
      </AntdForm>
    </section>
  );
};

export default Details;
