import qs from 'querystring';
import Icon from '@ant-design/icons';
import { SortOrder } from 'antd/es/table/interface';
import moment from 'moment-timezone';
import { ActionType, ProColumns, RequestData } from '@ant-design/pro-table';
import { Button, Popover, Space } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../context/auth';
import { useContextNotifications } from '../../../../context/notifications';
import {
  IReport,
  useReportDelete,
  useReportDuplicate,
  useReportShare,
  useTableReportsRow,
} from '../../../../hooks/reports';
import { JsonResult } from '../../../../types';
import { getSorterParams, queryFilterParams } from '../../../../utils';
import { copyTextToClipboard } from '../../../../utils/text';
import { Delete, Dots, Duplicate, Edit, Link as LinkIcon, Eye, Bell } from '../../../Common/Icon';
import Loading from '../../../Common/Loading';

import styles from '../index.module.less';

import Table from '../../../Common/Table';
import Status from '../Status';

function ReportsTable(): JSX.Element {
  const { user } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const { openConfirm, openNotification } = useContextNotifications();
  const reportDuplicate = useReportDuplicate();
  const reportDelete = useReportDelete();
  const reportShare = useReportShare();
  const reportsGet = useTableReportsRow();
  const actionRef = useRef<ActionType>();
  const [params, setParams] = useState<JsonResult>({});
  const [popoverVisible, setPopoverVisible] = useState<boolean>(true);

  useEffect(() => {
    const { search } = qs.parse(location.search.replace('?', ''));

    setParams({ search });

    actionRef.current?.reload();
  }, [location]);

  const tableRequest = (
    {
      current,
      pageSize,
      ...args
    }: Record<string, string> & {
      pageSize?: number | undefined;
      current?: number | undefined;
      keyword?: string | undefined;
    },
    sorter: Record<string, SortOrder>
  ): Promise<Partial<RequestData<IReport>>> => {
    const newParams = queryFilterParams({
      page: current ? `${current}` : '1',
      limit: pageSize ? `${pageSize}` : '10',
      ...params,
      ...args,
      ...getSorterParams(sorter),
    });

    return reportsGet.fetch({ ...newParams, userId: user.id }).then((data) => {
      if (data) {
        const { reports, total } = data;

        return { data: reports || [], success: true, total };
      }

      return { data: [], success: false, total: 0 };
    });
  };

  const handleReportDuplicate = (reportId: string, handleClose: () => void) => {
    reportDuplicate.fetch({ reportId: reportId.toString(), userId: user.id }).then(() => {
      handleClose();
      togglePopover();
      openNotification?.({ message: 'Report duplicated successfully' });
      actionRef.current?.reload();
    });
  };

  const handleReportDelete = (id: string, handleClose: () => void) => {
    reportDelete.fetch(id.toString()).then(() => {
      handleClose();
      togglePopover();
      openNotification?.({ message: 'Report deleted successfully' });
      actionRef.current?.reload();
    });
  };

  const togglePopover = () => {
    setPopoverVisible((prevVisible) => !prevVisible);
  };

  const handleCopyLink = (id: string) => {
    copyTextToClipboard(`${window.location.origin}/reports/${id}`).then(() => {
      openNotification?.({ message: 'The link is copied to the clipboard' });
      togglePopover();
    });
  };

  const tableRowActions = (row: IReport) => (
    <Space direction="vertical" className={styles.popoverContent}>
      <Loading visible={reportShare.loading || reportDuplicate.loading || reportDelete.loading} absolute />
      <Button
        type="ghost"
        icon={<Icon component={Edit} />}
        block
        onClick={() => {
          navigate(`/reports/${row.id}/edit`);
        }}
      >
        Edit
      </Button>
      <Button
        type="ghost"
        icon={<Icon component={Duplicate} />}
        block
        onClick={() => {
          togglePopover();
          openConfirm?.({
            title: 'Duplicate Report ',
            content: `Are you sure you want to duplicate report ${row.name ?? ''}?`,
            okText: 'Duplicate',
            maskClosable: true,
            onOk: (handleClose) => handleReportDuplicate(row.id, handleClose),
            onCancel: () => togglePopover(),
            afterClose: () => togglePopover(),
          });
        }}
      >
        Duplicate report
      </Button>
      <Button
        type="ghost"
        icon={<Icon component={Bell} />}
        block
        onClick={() => {
          navigate(`${row.id}/activities`);
        }}
      >
        See activities
      </Button>
      <Button
        type="ghost"
        icon={<Icon component={Eye} />}
        block
        onClick={() => {
          window.location.href = `https://mortgage-review.app/reports/${row.id}/preview`;
        }}
      >
        Preview
      </Button>
      <Button
        type="ghost"
        icon={<Icon component={Delete} />}
        block
        onClick={() => {
          togglePopover();
          openConfirm?.({
            title: 'Delete Report ',
            content: `Are you sure you want to delete report ${row.name ?? ''}?`,
            okText: 'Delete',
            okType: 'danger',
            maskClosable: true,
            onOk: (handleClose) => handleReportDelete(row.id, handleClose),
            onCancel: () => togglePopover(),
            afterClose: () => togglePopover(),
          });
        }}
      >
        Delete
      </Button>
      <Button
        type="ghost"
        icon={<Icon component={LinkIcon} />}
        block
        onClick={() => {
          togglePopover();
          handleCopyLink(row.id);
        }}
      >
        Copy link
      </Button>
      {/* {row.status !== "completed" && <Button
        type="ghost"
        icon={<Icon component={Share} />}
        block
        onClick={() => {
          togglePopover()
          openModal?.({
            type: ModalTypes.share,
            onOk: handleReportShare,
            data: { reportId: row.id, link: `https://mortgage-review.app/reports/${row.id}` },
            afterClose: () => togglePopover()
          })
        }
        }
      >
        Share
      </Button>} */}
    </Space>
  );

  const columns: ProColumns<IReport>[] = [
    {
      title: 'name',
      dataIndex: 'name',
      sorter: false,
      render: (_, row) => (
        <Link to={`${window.location.origin}/reports/${row.id}/edit`}>{row.name || '(Unnamed Report)'}</Link>
      ),
    },
    {
      title: 'date created',
      dataIndex: 'createdAt',
      sorter: false,
      renderText: (createdAt) => moment(createdAt).format('ddd DD MMM, YYYY hh:mm a'),
    },
    {
      title: 'status',
      dataIndex: 'status',
      sorter: false,
      renderText: (status) => <Status status={status} />,
    },
    {
      title: 'ACTION',
      sorter: false,
      render: (_, row) => (
        <div className="table-hover-actions">
          {popoverVisible && (
            <Popover content={() => tableRowActions(row)} placement="bottomRight" trigger="click" showArrow={false}>
              <Button size='large' type="ghost" icon={<Icon component={Dots} />} />
            </Popover>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table<IReport>
      search={false}
      columns={columns}
      request={tableRequest}
      actionRef={actionRef}
      showSorterTooltip={false}
      toolBarRender={false}
      pagination={{
        defaultPageSize: 50,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100'],
      }}
    />
  );
}

ReportsTable.defaultProps = {
  openModal: undefined,
};

export default ReportsTable;
