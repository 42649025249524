import { Col, Slider, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useWatch } from 'antd/es/form/Form';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { DraggableProvided } from 'react-beautiful-dnd';
import useDebounce from '../../../../../hooks/debounce';
import { useCalculateSavings } from '../../../../../hooks/reports';
import { IBarChartData } from '../../../../Common/Charts/types';
import ReportSection from '../Section';
import { useContextReports } from '../../../../../context/reports';
import BarChart from '../../../../Common/Charts/BarChart';

interface IRefinanceSavings {
  provided?: DraggableProvided;
}

const RefinanceSavings = ({ provided }: IRefinanceSavings) => {
  const name = 'refinaceSavings';
  const calculateSavings = useCalculateSavings();
  const { report, loans } = useContextReports();
  const [barLength, setBarLength] = useState<number>(40);
  const [yearsSaving, setYearsSaving] = useState<number>(10);
  const [data, setData] = useState<IBarChartData>();
  const [response, setResponse] = useState<number[]>([]);
  const form = useFormInstance();
  const isSectionActive = useWatch(['reportView', name, 'active'], form);
  const debouncedUpdateYears = useDebounce(yearsSaving, 700);

  useEffect(() => {
    if (report) {
      setYearsSaving(Number(report.yearsSaving));
    }
  }, []);

  useEffect(() => {

    if (!debouncedUpdateYears || !loans || !report || !isSectionActive) return

    const maxYears = Math.max(...loans.map((loan) => Number(loan.loanPeriodInYears)));

    setBarLength(maxYears);

    if (debouncedUpdateYears) {
      fetchcalculateSavings(Number(debouncedUpdateYears))
    } else {
      fetchcalculateSavings(yearsSaving);

    }
  }, [debouncedUpdateYears, loans, report, isSectionActive]);

  const onValueUpdate = (value: number) => {
    setYearsSaving(value);
  };

  const fetchcalculateSavings = (value: number) => {
    calculateSavings.fetch({ yearsSaving: value }, report?.id).then((res) => {
      if (res?.data && res?.data?.length) {
        setResponse(res.data);
      }
    });
  }

  useEffect(() => {
    if ((response?.length ?? 0) <= 1) return;
    if (!loans) return;

    const labels = loans.map((loan, index) => {
      if (loan.order === '1') {
        return 'Current';
      }

      if (loan.name) {
        return loan.name;
      }

      return `REFI Option ${index}`;
    });

    const maxThickness = 300;
    const barThickness = maxThickness / loans.length;

    const newData: IBarChartData = {
      labels,
      datasets: [
        {
          barThickness,
          label: 'Refinance Savings',
          data: response,
          inflateAmount: 1,
          backgroundColor: '#1e0c68',
          borderRadius: {
            topLeft: 4,
            topRight: 4,
          },
        },
      ],
    };

    setData(newData);
  }, [response, loans, report, isSectionActive]);

  const marks = {
    1: { label: '1 year' },
    [Math.floor(barLength / 2)]: { label: `${Math.floor(barLength / 2)} years` },
    [barLength]: { label: `${barLength} years` },
  };

  const isYearMarkNeeded = !marks[yearsSaving] &&
    ![1, Math.floor(barLength / 2), barLength - 1].includes(yearsSaving) &&
    ![yearsSaving - 1, yearsSaving + 1].includes(Math.floor(barLength / 2)) &&
    yearsSaving !== 2 && yearsSaving !== 1;

  if (isYearMarkNeeded) {
    marks[yearsSaving] = { label: `${yearsSaving} years` };
  }

  return (
    <ReportSection
      id={name}
      name={name}
      title='Refinance Savings'
      provided={provided}
    >
      {loans && loans?.length <= 1 ? (
        <div className='flex-col flex-align-center flex-justify-center mh-200'>
          <div className='p-block-10 p-inline-10 bg-light-gray br-4'>
            <Typography className='color-dark-blue'>
              No loans to compare. Please add more loans to see refinance savings.
            </Typography>
          </div>
        </div>
      ) : (
        <>
          <Col style={{ padding: '50px' }}>
            <Slider
              style={{ width: '100%' }}
              className='slider-dots-less'
              min={1}
              max={barLength}
              marks={marks}
              step={1}
              trackStyle={{ background: 'var(--color-dark-blue)' }}
              handleStyle={{
                background: 'var(--color-light-light-gray)',
                border: 0,
                boxShadow: '0px 2px 4px -2px var(--color-box-shadow)',
              }}
              value={yearsSaving || report?.yearsSaving}
              onChange={onValueUpdate}
            />
          </Col>
          <Col style={{ minHeight: '400px' }}>
            <BarChart withLabels data={data} mode='nearest' hideLegend loading={calculateSavings.loading} />
          </Col>
        </>
      )}
    </ReportSection>
  );
};

RefinanceSavings.defaultProps = {
  provided: undefined,
};

export default RefinanceSavings;
