import { Space, Tooltip, Typography } from 'antd';
import type * as CSS from 'csstype';
import React from 'react';
import { IBarChartData, ILineChartData } from '../types';

interface ILegend {
  data: IBarChartData | ILineChartData;
  children?: React.ReactNode;
  reversed: boolean;
}

const ChartLegend = ({ data, children, reversed }: ILegend) => {
  const datasets = reversed ? [...data.datasets].reverse() : [...data.datasets];

  return (
    <div className="flex-row flex-justify-space-between gap-20">
      <div>
        <div className="flex-row gap-20" style={{ flexWrap: 'wrap' }}>
          {datasets.map((dataset) => (
            <Tooltip key={dataset.label} title={dataset.details}>
              <Space size={4} style={{ whiteSpace: 'nowrap' }}>
                {dataset.backgroundColor && (
                  <div
                    style={{
                      width: 12,
                      height: 12,
                      borderRadius: 12,
                      backgroundColor: dataset.backgroundColor as CSS.Property.BackgroundColor,
                    }}
                  />
                )}
                <Typography.Text className="color-gray fs-1">{dataset.label}</Typography.Text>
                {dataset?.total && <Typography.Text className='fs-1'>: {dataset.total}</Typography.Text>}
              </Space>
            </Tooltip>
          ))}
        </div>
      </div>
      <div>{children}</div>
    </div>
  );
};

ChartLegend.defaultProps = {
  children: undefined,
};

export default ChartLegend;
