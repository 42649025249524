/* eslint-disable prefer-promise-reject-errors */
import Icon, { CheckOutlined } from '@ant-design/icons';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js';
import { Button, Space, Radio, RadioChangeEvent, Row, Col, List } from 'antd';
import clsx from 'clsx';
import React, { useState } from 'react';
import Stripe from 'stripe';
import { useNavigate } from 'react-router-dom';
import { Plus } from '../Icon';
import StripeForm from '../PaymentForm';
import PaymentItem from '../PaymentItem';

import styles from './index.module.less';
import { usePlanId, usePaymentMethodId, usePaymentCreate } from '../../../hooks/stripe';
import { setDataInStorage } from '../../../utils/storage';
import { formatPrice } from '../../Pages/Auth/Setup/SelectPlan/PlanRow';

interface IPaymentList {
  paymentMethods?: Stripe.PaymentMethod[];
  selectedId: string | null;
  loading?: boolean;
  reloadPaymentMethods: () => void;
  handleNext?: (id: string | null) => void;
  handleBack?: () => void;
  isControlsBlock?: boolean;
  cancelButtonText?: string;
  confirmButtonText?: string;
  selectedPlan?: string | null;
  promoCode?: string;
  discount?: any;
  plan?: Stripe.Plan | undefined;
}

const BULLET_LIST = [
  { title: 'No Contract' },
  { title: 'Unlimited Reports' },
  { title: 'Cancel Anytime' },
  { title: 'Get Started in 30 seconds' },
]

const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY as string);

const PaymentsList = ({
  selectedPlan,
  promoCode,
  paymentMethods,
  selectedId,
  loading,
  reloadPaymentMethods,
  handleNext,
  handleBack,
  isControlsBlock,
  cancelButtonText,
  confirmButtonText,
  discount,
  plan
}: IPaymentList) => {
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [currentPaymentMethod, setCurrentPaymentMethod] = useState<string | null>(selectedId);
  const planId = usePlanId();
  const paymentMethodId = usePaymentMethodId();
  const paymentCreate = usePaymentCreate();
  const navigate = useNavigate();
  const [loadingData, setLoadingData] = useState<boolean>(false);

  const options: StripeElementsOptions = {
    mode: 'setup',
    locale: 'en',
    loader: 'always',
    currency: 'usd',
    paymentMethodCreation: 'manual',
    paymentMethodTypes: ['card'],
    appearance: {
      variables: {
        colorPrimary: getComputedStyle(document.documentElement).getPropertyValue('--color-dark-blue'),
      },
    },
  };

  const handeNextStep = (paymentMethod: Stripe.PaymentMethod | null) => {
    if (!plan || !paymentMethod) return;
  
    setLoadingData(true);
  
    paymentCreate
      .fetch({
        pmId: paymentMethod.id,
        planId: plan.id,
        promoCode,
        isFreeTrial: !!planId?.data?.data.trial_period_days,
      })
      .then(() => {
        setDataInStorage('signUpData', {});
        const purchaseValue = formatPrice((plan?.amount ?? 0) * ((100 - discount) / 100)) || 0;
  
        // Facebook Pixel
        window.fbq('track', 'Purchase', {
          value: purchaseValue,
          currency: 'USD',
          discount,
        });
  
        // Google Tag Manager
        if (!window.dataLayer) {
          window.dataLayer = [];
        }
        window.dataLayer.push({
          event: 'Purchase',
          value: purchaseValue,
          currency: 'USD',
          discount,
        });
  
        // Reddit Pixel
        window.rdt('track', 'Purchase', {
          value: purchaseValue,
          currency: 'USD',
          discount,
        });
  
        navigate('/profile');
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  // const handleAddNewCard = (id: string | null) => {
  //   reloadPaymentMethods();
  //   setIsEditMode(false);
  //   handeNextStep(id);
  //   window.fbq('track', 'AddPaymentInfo')

  //   window.dataLayer = window.dataLayer || [];
  //   window.dataLayer.push({
  //     'event': 'AddPaymentInfo',
  //     'paymentMethodId': id
  //   });

  //   // Reddit Pixel
  //   window.rdt('track', 'AddPaymentInfo');
  // };

  // const handleRadioChange = (e: RadioChangeEvent) => {
  //   const { value } = e.target;

  //   setCurrentPaymentMethod(value);
  // };

  return (
    <>
      <List
        size="small"
        grid={{ gutter: 0, column: 2 }}
        dataSource={BULLET_LIST}
        renderItem={({ title }) => (
          <List.Item>
            <List.Item.Meta
              avatar={<CheckOutlined style={{ color: 'green' }} />}
              title={title}
            />
          </List.Item>
        )}
      />
      {/* {!isEditMode && !!paymentMethods?.length && (
        <Space size={24} direction="vertical">
          <Radio.Group value={currentPaymentMethod} onChange={handleRadioChange}>
            <Space align="end" className={styles.row}>
              <Space direction="vertical" size={20}>
                {paymentMethods.map((paymentMethod) => (
                  <Radio key={paymentMethod.id} value={paymentMethod.id}>
                    <PaymentItem card={paymentMethod.card} />
                  </Radio>
                ))}
              </Space>
              <Button
                className={styles.button}
                type="ghost"
                icon={<Icon component={Plus} />}
                onClick={() => setIsEditMode(true)}
              >
                Add New
              </Button>
            </Space>
          </Radio.Group>
          {(handleBack || handleNext) && (
            <Row gutter={24} className={clsx({ 'flex-justify-end': !isControlsBlock })}>
              {handleBack && (
                <Col span={12} className={clsx({ 'w-fit-content': !isControlsBlock })}>
                  <Button size="large" type="default" block={isControlsBlock} onClick={handleBack} disabled={loading}>
                    {cancelButtonText}
                  </Button>
                </Col>
              )}
              {handleNext && (
                <Col span={12} className={clsx({ 'w-fit-content': !isControlsBlock })}>
                  <Button
                    size="large"
                    type="primary"
                    block={isControlsBlock}
                    onClick={() => handeNextStep(currentPaymentMethod)}
                    loading={loading}
                    disabled={loading || !currentPaymentMethod}
                  >
                    {confirmButtonText}
                  </Button>
                </Col>
              )}
            </Row>
          )}
        </Space>
      )} */}

      {
        <Elements stripe={stripePromise} options={options}>
          <StripeForm
            loading={loadingData}
            setLoading={setLoadingData}
            isControlsBlock={isControlsBlock}
            confirmButtonText={confirmButtonText}
            cancelButtonText={cancelButtonText}
            options={options}
            handelConfirm={handeNextStep}
            // handleNext={()=> handleAddNewCard(currentPaymentMethod)}
            handleBack={!paymentMethods?.length && handleBack ? handleBack : () => setIsEditMode(false)}
          />
        </Elements>
      }
    </>
  );
};

PaymentsList.defaultProps = {
  paymentMethods: undefined,
  loading: false,
  handleNext: undefined,
  handleBack: undefined,
  isControlsBlock: false,
  confirmButtonText: 'Confirm',
  cancelButtonText: 'Back',
  promoCode: undefined,
  selectedPlan: null, 
  discount: 0, 
  plan: undefined, 
};
export default PaymentsList;
